import React, {useEffect, useState} from "react";
import Card from "../Generic/card";
import ServiceCard from "../Generic/serviceCard";
import {Element} from "react-scroll";
import Aos from "aos";
import "aos/dist/aos.css";
import {useValue} from "../../useValue";

const Services = ({service, text}) => {
    // State to store the fetched data
    const [data, setData] = useState({
        a: [],
        b: [],
    });

    const {anotherValue} = useValue();

    useEffect(() => {
        Aos.init();
        const arrs = anotherValue?.data?.[1].part_details
            // .sort((a, b) => a.index - b - index)
            .filter(
                (item) => item.logo !== null
            );

        console.log(arrs)

        if (window?.innerWidth < 768) {
            const a = arrs?.slice(0, Math.floor(arrs.length / 2))
            const b = arrs?.slice(Math.floor(arrs.length / 2), arrs.length);

            console.log(0, Math.floor(arrs.length / 2))
            console.log(Math.floor(arrs.length / 2), arrs.length)

            setData({
                a,
                b,
            });
        } else {
            const a = arrs?.filter((item) => item.index % 2 === 0);
            const b = arrs?.filter((item) => item.index % 2 !== 0);
            a?.sort((a, b) => a?.index - b?.index);
            b?.sort((a, b) => a?.index - b?.index);

            setData({
                a,
                b,
            });
        }
    }, [anotherValue]);
    const imageles = anotherValue.data?.[1].part_details?.filter((item) => item.logo === null)
    return (
        <>
            <Card
                // color="bg-white lg:flex lg:pt-[80px] lg:flex-row md:h-[1590px] lg:h-[1390px] relative flex flex-col px-[40px] md:pb-[40px]"
                color="bg-white lg:flex lg:pt-[80px] lg:flex-row relative flex flex-col md:px-[40px] md:pb-[40px] md:pt-[50px] pt-[30px] px-[20px]"
                id="@services"
            >
                <div
                    className="flex flex-col mb-[36px] lg:mb-[190px] lg:w-[50%]">
                    <Element name="services"></Element>
                    <div
                        className="title"
                        data-aos="flip-up"
                        data-aos-duration={700}
                        data-aos-delay={0}
                    >
                        {imageles?.[0].header || "Наши услуги"}
                    </div>
                    <div
                        className=" lg:mt-[32px] mt-[24px] max-w-[550px] text-[18px] leading-[25px] md:leading-[33px] md:text-[26px] text-[#767575]"
                        data-aos="fade-up"
                        data-aos-duration={700}
                        data-aos-delay={0}
                    >
                        {imageles?.[0].content ||
                            `Наш веб-сервис предлагает полный спектр услуг, начиная от помощи в
            подготовке к контрольным и итоговым работам, и заканчивая помощью в
            выполнении квизов и тестов.`}
                    </div>
                </div>

                <div className="lg:w-[50%] md:flex gap-[20px]">
                    <div className=" md:mt-[200px]  flex flex-col gap-[20px] w-full">
                        {data?.a?.map((item) => (
                            <ServiceCard
                                num={`0${item.index}`}
                                text={item?.header}
                                img={item?.logo}
                                delay={item.index * 50}
                            />
                        ))}
                    </div>

                    <div className="flex flex-col mt-[20px] lg:mt-[0px] gap-[20px] w-[100%]">
                        {data?.b?.map((item) => (
                            <ServiceCard
                                num={`0${item.index}`}
                                text={item?.header}
                                img={item?.logo}
                                delay={item.index * 50}
                            />
                        ))}
                        <Element name="contact"></Element>
                    </div>
                </div>
            </Card>
        </>
    );
};

export default Services;
