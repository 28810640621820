import React from 'react'

const Card = ({children,block, color}) => {
  return (
    <>
    <div className={`${!block ? 'lg:my-[20px]  md:m-[15px] m-[10px]': 'm-0'} rounded-[32px]  md:rounded-[40px] ${color}`}>{children}</div>
    </>
  )
}

export default Card