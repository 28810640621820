import React from 'react'
import women from './../../../../image/women.png'

const SwiperCard = ({article, hero}) => {
  const IMGLINK = `https://api.xiam.uz/storage/`

  return (
    <>
     <section className='rounded-[20px] bg-gray-mainGray h-full w-full md:p-[32px] p-[24px]'>
        <div>
            <img className='md:mb-[56px] mb-[48px] !size-[60px] md:!size-[80px]' src={hero ===null ?  women : IMGLINK + hero} alt="avatar" />
        </div>
        <div className='w-full !text-start swiper-text'>{article===null ? 'Я хочу выразить свою глубокую благодарность команде за их профессионализм и высокий уровень обслуживания. Я обратился к этой компании с просьбой помочь мне с написанием эссе':article}</div>
        </section>   
 
    </>
  )
}

export default SwiperCard