import logo from "./../../svg/preloader-gif.gif";
import {useEffect, useState} from "react";

export default function Preloader() {
    const [active, setActive] = useState(false)

    useEffect(() => {
        setActive(true)

        const timeOut = setTimeout(() => setActive(false), 2000)
    }, [])

    return (
        <div className={active ? 'preloader active' : 'preloader'}>
            <img src={logo} alt="preloader"/>
        </div>
    )
}