import React, { useEffect } from "react";
import Button from "../Generic/Button";
import Card from "../Generic/card";
import { useValue } from "./../../useValue";
import Aos from "aos";
import "aos/dist/aos.css";
import './style.css'
import { Element } from "react-scroll";
const Why = () => {
  const { setValue } = useValue();
  const { anotherValue } = useValue();

  const handleClick = () => {
    setValue('Почему мы? -> Заказать сейчас'); // Set the value using the context function
  };
  useEffect(() => {
    Aos.init();
  }, []);
  // Function to fetch data
  const IMGLINK = `https://api.xiam.uz/storage/`;

  const arr = anotherValue.data?.[0].part_details.slice(1);
  const imageles = anotherValue.data?.[0].part_details?.find((item)=>item.image === null)

  console.log(imageles)

  return (
    <Card color={"bg-white md:pb-[40px] md:pt-[60px] md:px-[40px] pb-[20px] px-[20px] pt-[30px]"} id="about">
      <Element name="about"></Element>
      <div id={'about'} className="lg:flex w-[100%] md:justify-between items-start">
        <div>
          <h2
              className="title"
              data-aos="flip-up"
              data-aos-duration={700}
              data-aos-delay={0}
          >
            {imageles.header || "Почему мы?"}
          </h2>
        </div>
        <div
            className=" mt-[20px]  flex flex-col leading-[33px] lg:max-w-[calc(55%-50px)] gap-[12px]"
        >
          <div className="flex flex-col">
            <p
                className={'why-desc pb-[12px]'}
                data-aos="fade-up"
                data-aos-duration={700}
                data-aos-delay={0}
            >
              {imageles.content ||
                `Наши консультанты обеспечивают помощь по всем типам академических
            заданий, начиная от рефератов и контрольных работ и заканчивая
            диссертациями.`}
            </p>
          </div>
          <div
              className="md:mt-[32px] mt-[24px]"
              data-aos="fade-up"
              data-aos-duration={700}
              data-aos-delay={0}
          >
            <Button
              img={true}
              color="green"
              onclick={() => handleClick()}
              clasName="bg-green-brandGreen text-[18px] lg:text-[24px]  transition-all !rounded-[50px] duration-500 h-[58px] lg:h-[72px] hover:bg-transparent hover:text-green-brandGreen text-white md:px-[32px] md:py-[24px] px-[20px] py-[20px]"
            >
              Заказать сейчас
            </Button>
          </div>
        </div>
      </div>
      <div className="flex-col flex gap-[20px] w-[100%] justify-center md:w-[100%]  lg:flex-row pt-[40px] md:pt-[50px] ">
        {arr?.map((value, index) => (
          <div
            block={true}
            className="bg-green-brandGreen rounded-[32px]  md:rounded-[40px]   py-[20px] px-[20px] !pt-[40px] !pr-[40px] !pl-[40px] pb-[200px] lg:w-[calc(100%/3)] lg:pb-[250px] relative overflow-hidden text-[white]"
            data-aos="fade-in"
            data-aos-duration={700}
            data-aos-delay={index * 100}
          >
            <div className=" w-[100%] ">
              <div className="text-[32px] headerCard max-w-[400px] lg:leading-[43px] xl:text-[47px] why-text">
                {value.header}
              </div>
              <div className="md:mt-[24px] mt-[16px] text-[18px] lg:text-[20px] md:text-[20] flex flex-col gap-[10px]">
                <div className="text-[#CCE5DF] headerContent lg:w-[380px] why-sub max-w-[100%]">
                  {value.content}
                </div>
              </div>
              <div className="absolute bottom-[-50px] right-[-50px]">
                <img
                  className="mix-blend-multiply size-[200px] md:size-[280px]"
                  src={IMGLINK + value.image}
                  alt="unique"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default Why;
