import React, { useState } from "react";
import plus from "./../../../svg/plus.svg";

const Quests = ({question, answer, delay}) => {
  const [active, setActive] = useState("");
  const handleClick = () => {
    setActive((active) => !active);
  };
  return (
    <div
        className="rounded-[20px] cursor-pointer bg-gray-mainGray transition-all duration-300 md:p-[36px] p-[24px]"
        data-aos="fade-up"
        data-aos-duration={700}
        data-aos-delay={0}
    >
      <div
        onClick={() => handleClick()}
        className="flex justify-between items-center cursor-pointer rounded-[20px] bg-gray-mainGray "
      >
        <div className="cursor-pointer faq-title">{question || 'Question'}</div>
        <div>
          <img className={`size-[23px] ${active ? 'rotate-[135deg] transition-all cursor-pointer duration-300' : 'transition-all cursor-pointer duration-300 rotate-[0deg]'}`} src={plus} alt="" />
        </div>
      </div>
      <div className="overflow-hidden">
      <div className={`md:pt-[24px] pt-[16px] faq-text transition-all  ease-in-out duration-500 max-w-[700px] ${!active ? 'hidden h-[0px]  transition-all ease-in-out duration-1000' : ' transition-all ease-in-out h-auto duration-1000 flex'}`}>
        {answer || "Answer"}
      </div>
      </div>
    </div>
  );
};

export default Quests;
