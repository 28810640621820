import React, { useEffect } from "react";
import Card from "../Generic/card";
import Aos from "aos";
import "aos/dist/aos.css";
import { useValue } from "../../useValue";
const Header = () => {
  const { anotherValue } = useValue();
  const theHeader = anotherValue.data?.[5].part_details?.[0]?.header;
  const theContent = anotherValue.data?.[5].part_details?.[0]?.content;

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      {/* style={{border: "8px solid transparent", padding: "10px", borderImage: "url('path-to-your-dash-pattern.png') 30 round";}} */}
      <Card color={"bg-green-brandGreen"}>
        <section className="flex flex-col items-center justify-center lg:py-[230px] md:py-[200px] py-[180px] md:px-[40px] px-[20px] w-full">
          <h1
            className="intro-title"
            data-aos="zoom-in-up"
            data-aos-delay="500"
          >
            {theHeader || "XIAM - платформа для твоей учебы."}
          </h1>
          <p
            className="intro-text"
            data-aos="zoom-in-up"
            data-aos-delay="500"
          >
            {theContent || "Найди нужного эксперта для своего задания."}
          </p>
        </section>
      </Card>
    </>
  );
};

export default Header;
