import React, {useState} from "react";
import menu from "./../../../svg/menu.svg";
import logo from "./../../../svg/logo.svg";
import close from "./../../../svg/close.svg";
import Button from "../../Generic/Button";
import Popup from "../../Generic/Button/Popup";
import Social from "../../Generic/Social";
import {Link} from "react-scroll";

const ResponsiveHeader = ({change}) => {
    const [active, setActive] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const handleOpen = () => {
        setIsOpen(true); // Open the popup when button is clicked
    };

    const handleClose = () => {
        setIsOpen(false); // Close the popup
    };
    const handleClick = () => {
        setActive((current) => !current);
    };

    const link = [
        {
            name: "Услуги",
            link: "#services1",
        },
        {
            name: "О нашем проекте",
            link: "#about",
        },
        {
            name: "Контакты",
            link: "#contacts",
        },
        {
            name: "FAQ",
            link: "#faq",
        },
        {
            name: "Отзывы",
            link: "#review",
        },
    ];
    return (
        <>
            <button
                onClick={() => handleClick()}
                className="flex align-center items-center h-[20px] w-[25px] sm:flex md:hidden"
            >
                {change === "white" ? (
                    <svg
                        width="30"
                        height="14"
                        viewBox="0 0 30 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0 1C0 0.447715 0.447715 0 1 0H13C13.5523 0 14 0.447715 14 1C14 1.55228 13.5523 2 13 2H1C0.447715 2 0 1.55228 0 1Z"
                            fill="black"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M16 13C16 12.4477 16.4477 12 17 12H29C29.5523 12 30 12.4477 30 13C30 13.5523 29.5523 14 29 14H17C16.4477 14 16 13.5523 16 13Z"
                            fill="black"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0 7C0 6.44772 0.447715 6 1 6H29C29.5523 6 30 6.44772 30 7C30 7.55228 29.5523 8 29 8H1C0.447715 8 0 7.55228 0 7Z"
                            fill="black"
                        />
                    </svg>
                ) : (
                    <img
                        className="h-full flex justify-center items-center w-full"
                        src={menu}
                        alt=""
                    />
                )}
            </button>
            <div
                className={`fixed bg-[white] h-[100%] z-[99] w-[100%] top-0 right-0  overflow-x-auto  ${
                    active ? "nav show" : " flex justify-center items-center nav"
                }`}
            >
                <div className="z-[999] flex justify-start h-[100%] w-[100%] items-start p-[10px]">
                    <div className="h-[740px] w-[100vh] mb-[10px] rounded-[30px] p-[30px] bg-green-brandGreen">
                        <div className="flex justify-between items-center">
                            <div className="md:h-[50px] md:w-[50px] h-[45px] w-[45px]  cursor-pointer">
                                <img className="h-full w-full" src={logo} alt=""/>
                            </div>
                            <button
                                onClick={() => handleClick()}
                                className="md:h-[50px] md:w-[40px] h-[25px] w-[25px]  cursor-pointer"
                            >
                                <img className="h-full w-full" src={close} alt=""/>
                            </button>
                        </div>
                        <div>
                            <div
                                className={`text-white md:text-[10px] ${"flex flex-col items-start gap-[20px] mt-[70px]"}   `}
                            >
                                {link.map((item) => (
                                    <div
                                        onClick={() => handleClick()}
                                        className={'flex'}
                                    >
                                        <Link
                                            className="p-[10px] md:text-[15px] borderc lg:text-[18px] hover:opacity-70 cursor-pointer transition-all duration-200  rounded-[10px]"
                                            to={item.link}
                                            smooth={true}
                                            duration={300}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                const el = document.querySelector(item.link)

                                                setActive(false)

                                                if (el) {
                                                    window.scroll({
                                                        behavior: 'smooth',
                                                        // @ts-ignore
                                                        top: el.offsetTop - 135
                                                    })
                                                } else console.error('The element you want to access does not exist! | scroll.util.ts')
                                            }}
                                        >
                                            {item.name}
                                        </Link>
                                    </div>
                                ))}
                            </div>
                            {/* <Navbar responsive={true} flex={false} /> */}
                            <div className="mt-[40px]">
                                <Button
                                    onclick={() => handleOpen()}
                                    color="white"
                                    clasName="bg-white"
                                >
                                    Ознакомиться с услугами
                                </Button>
                                <Popup isOpen={isOpen} onClose={handleClose}/>
                            </div>
                            <div>
                                <Social paddang={"pt-[100px]"} around={"p-[15px]"}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ResponsiveHeader;
