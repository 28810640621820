import React from "react";
import "./style.css";
import { Link } from "react-scroll";

const Navbar = ({ flex, color,span, dash, responsive }) => {
  return (
    <div
      className={`${color || "text-white"} md:text-[10px] ${
        responsive ? "flex flex-col items-start gap-[20px] mt-[70px]" : "hidden"
      }   ${flex && "gap-[20px] md:flex lg:flex"}`}
    >
      <Link
        className={`${dash} h-[50px] p-[6px] md:text-[15px] flex justify-center items-center navbar  cursor-pointer transition-all duration-200  rounded-[10px]`}
        to="/"
        smooth={true}
        duration={300}
        onClick={(e) => {
          e.preventDefault()
          const el = document.querySelector('#services1')

          if (el) {
            window.scroll({
              behavior: 'smooth',
              // @ts-ignore
              top: el.offsetTop - 135
            })
          } else console.error('The element you want to access does not exist! | scroll.util.ts')
        }}
      >
        <span className={`${span} h-[38px] p-[10px] flex justify-center items-center cursor-pointer  transition-all duration-200 rounded-[8px] `}>
        Услуги
        </span>
      </Link>

      <Link
        className={`${dash} h-[50px] p-[6px] md:text-[15px] flex justify-center items-center navbar  cursor-pointer transition-all duration-200  rounded-[10px]`}
        smooth={true}
        duration={300}
        to="/"
        onClick={(e) => {
          e.preventDefault()
          const el = document.querySelector('#about')

          if (el) {
            window.scroll({
              behavior: 'smooth',
              // @ts-ignore
              top: el.offsetTop - 135
            })
          } else console.error('The element you want to access does not exist! | scroll.util.ts')
        }}
      >
        <span className={`${span} h-[38px] p-[10px] flex justify-center items-center cursor-pointer  transition-all duration-200 rounded-[8px] `}>
        О нашем проекте
        </span>
      </Link>

      <Link
        className={`${dash} h-[50px] p-[6px] md:text-[15px] flex justify-center items-center navbar  cursor-pointer transition-all duration-200  rounded-[10px]`}
        smooth={true}
        duration={300}
        to="/"
        onClick={(e) => {
          e.preventDefault()
          const el = document.querySelector('#contacts')

          if (el) {
            window.scroll({
              behavior: 'smooth',
              // @ts-ignore
              top: el.offsetTop - 135
            })
          } else console.error('The element you want to access does not exist! | scroll.util.ts')
        }}
      >
        <span className={`${span} h-[38px] p-[10px] flex justify-center items-center cursor-pointer  transition-all duration-200 rounded-[8px] `}>
        Контакты
        </span>
      </Link>

      <Link
        className={`${dash} h-[50px] p-[6px] md:text-[15px] flex justify-center items-center navbar  cursor-pointer transition-all duration-200  rounded-[10px]`}
        smooth={true}
        duration={300}
        to="/"
        onClick={(e) => {
          e.preventDefault()
          const el = document.querySelector('#faq')

          if (el) {
            window.scroll({
              behavior: 'smooth',
              // @ts-ignore
              top: el.offsetTop - 135
            })
          } else console.error('The element you want to access does not exist! | scroll.util.ts')
        }}
      >
        <span className={`${span} h-[38px] p-[10px] flex justify-center items-center cursor-pointer  transition-all duration-200 rounded-[8px] `}>
        FAQ
        </span>
      </Link>

      <Link
        className={`${dash} h-[50px] p-[6px] md:text-[15px] flex justify-center items-center navbar  cursor-pointer transition-all duration-200  rounded-[10px]`}
        smooth={true}
        duration={300}
        to="/"
        onClick={(e) => {
          e.preventDefault()
          const el = document.querySelector('#review')

          if (el) {
            window.scroll({
              behavior: 'smooth',
              // @ts-ignore
              top: el.offsetTop - 135
            })
          } else console.error('The element you want to access does not exist! | scroll.util.ts')
        }}
      >
        <span className={`${span} h-[38px] p-[10px] flex justify-center items-center cursor-pointer  transition-all duration-200 rounded-[8px] `}>
        Отзывы
        </span>
      </Link>
    </div>
  );
};

export default Navbar;
