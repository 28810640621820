import React from "react";
import Card from "../Generic/card";
import "./index.css";
import logo from "./../../svg/logo.svg";
import Social from "../Generic/Social";
import {Link} from "react-scroll";
import {useValue} from "../../useValue";

const Footer = ({email}) => {
    const scrol = () => {
        document.documentElement.scrollTop = 0;
    };

    const {anotherValue} = useValue();

    const link = [
        {
            name: "Услуги",
            link: "services",
        },
        {
            name: "О нашем проекте",
            link: "about",
        },
        {
            name: "Контакты",
            link: "contact",
        },
        {
            name: "FAQ",
            link: "faq",
        },
        {
            name: "Отзывы",
            link: "review",
        },
    ];

    return (
        <Card color="bg-[#191919] p-[10px] md:p-[20px]">
            <div
                className="contact w-[100%] text-[15px] p-[30px] text-white flex flex-col lg:flex-row justify-center items-center">
                <div className="lg:w-[55%] w-[100%] ">
                    <div className="flex justify-between flex-wrap">
                        <div
                            className="flex flex-col justify-between"
                            data-aos="fade-in"
                            data-aos-duration={600}
                            data-aos-delay={0}
                        >
                            <div className="w-[50px]">
                                <img
                                    className="min-size-[50px] w-[50px] size-[50px]"
                                    src={logo}
                                    alt=""
                                />
                            </div>
                            <a
                                className="hidden md:flex cursor-pointer"
                                href={email || `mailto:${anotherValue.data?.[4].part_details?.[0]?.social_media_link}`}
                            >
                                {anotherValue.data?.[4].part_details?.[0]?.social_media_link}
                            </a>
                        </div>

                        <div
                            className="flex flex-col ml-[50px] md:ml-0 justify-between"
                            data-aos="fade-in"
                            data-aos-duration={600}
                            data-aos-delay={100}
                        >
                            <Social around={"p-[10px]"}/>
                            <div className="hidden md:flex">XIAM все права защищены</div>
                        </div>

                        <div
                            className={`flex flex-col mt-[36px] md:mt-[0px] leading-[32px] `}
                            data-aos="fade-in"
                            data-aos-duration={600}
                            data-aos-delay={200}
                        >
                            {link.map((item) => (
                                <Link to={item.link} smooth={true} duration={300}>
                                    <div className="hover:opacity-70 cursor-pointer">
                                        {item.name}
                                    </div>
                                </Link>
                            ))}
                        </div>

                        <div
                            className=" flex flex-col md:hidden pt-[36px] md:pt-[0] gap-[16px]"
                            data-aos="fade-in"
                            data-aos-duration={600}
                            data-aos-delay={300}
                        >
                            <a href="mailto:info@test.uz">info@test.uz</a>
                            <div>XIAM все права защищены</div>
                        </div>
                    </div>
                    <div className="flex justify-between max-w-[]"></div>
                </div>

                <div
                    className="md:w-[45%] md:h-[190px] relative flex flex-col justify-between w-[100%]"
                    data-aos="fade-in"
                    data-aos-duration={600}
                    data-aos-delay={300}
                >
                    {/* click to scroll top  */}
                    <div className="flex justify-end ">
                        <div onClick={() => scrol()} className="wraps hidden md:flex">
                            <div className={`cursor-pointer top rounded-full rotate-[90deg] p-[15px]`}>
                                <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M15.5 6.98756C15.5 6.43528 15.0523 5.98756 14.5 5.98756L4.22519 5.98756L7.94353 2.11906C8.31263 1.73506 8.31263 1.12812 7.94353 0.744116V0.744116C7.55318 0.338003 6.90342 0.338003 6.51307 0.744116L1.16608 6.30703C0.794025 6.6941 0.794026 7.3059 1.16608 7.69298L6.51307 13.2559C6.90342 13.662 7.55318 13.662 7.94353 13.2559V13.2559C8.31263 12.8719 8.31263 12.2649 7.94353 11.8809L4.22519 8.01244L14.5 8.01244C15.0523 8.01244 15.5 7.56472 15.5 7.01244L15.5 6.98756Z"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="flex gap-[27px] justify-end">
                        <div
                            className="flex gap-[16px] md:gap-[100px] md:absolute md:bottom-0  pt-[16px] flex-col-reverse md:flex-row justify-between">
                            <div className="hidden">Политика конфиденциальности</div>
                            <div>
                                Разработка сайта{" "}
                                <a
                                    className="underline hover:no-underline transition-all duration-500 cursor-pointer"
                                    href="https://usoft.uz/?utm_source=xiam_uz&utm_medium=footer_link"
                                >
                                    Usoft
                                </a>
                            </div>
                        </div>

                        {/* mobile component don't touch */}
                        <div className="justify-end md:hidden flex h-auto items-end">
                            <div onClick={() => scrol()} className="wraps  ">
                                <div className={`cursor-pointer top rounded-full rotate-[90deg] p-[15px]`}>
                                    <svg
                                        width="16"
                                        height="14"
                                        viewBox="0 0 16 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M15.5 6.98756C15.5 6.43528 15.0523 5.98756 14.5 5.98756L4.22519 5.98756L7.94353 2.11906C8.31263 1.73506 8.31263 1.12812 7.94353 0.744116V0.744116C7.55318 0.338003 6.90342 0.338003 6.51307 0.744116L1.16608 6.30703C0.794025 6.6941 0.794026 7.3059 1.16608 7.69298L6.51307 13.2559C6.90342 13.662 7.55318 13.662 7.94353 13.2559V13.2559C8.31263 12.8719 8.31263 12.2649 7.94353 11.8809L4.22519 8.01244L14.5 8.01244C15.0523 8.01244 15.5 7.56472 15.5 7.01244L15.5 6.98756Z"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default Footer;
