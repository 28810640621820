import React, {createContext, useContext, useEffect, useState} from 'react';

const ValueContext = createContext();

export const useValue = () => useContext(ValueContext);

export const ValueProvider = ({children}) => {
    const [isValueSet, setIsValueSet] = useState(false);
    const [anotherValue, setAnotherValue] = useState([]);

    const setValue = (value) => {
        setIsValueSet(value);
    };
    const setAnother = (value) => {
        setAnotherValue(value);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://api.xiam.uz/api/main-page');
                const jsonData = await response.json();
                setAnother(jsonData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData()
    }, [])

    return (
        <ValueContext.Provider value={{isValueSet, setValue, anotherValue, setAnother}}>
            {children}
        </ValueContext.Provider>
    );
};
