import React from "react";
import Button from "../Button";
// import Popup from "../Button/Popup";
import './style.css'
import {useValue} from './../../../useValue.js';

const ServiceCard = ({text, img, num, delay}) => {
    const {setValue} = useValue();
    const IMGLINK = `https://api.xiam.uz/storage/`

    const handleClick = (text) => {
        setValue(text); // Set the value using the context function
    }

    return (
        <div
            className="bg-[#EBF5F2] p-[10px] max-w-[440px]  rounded-[20px]"
            data-aos="fade-up"
            data-aos-duration={700}
            data-aos-delay={delay}
        >
            <div className="p-[20px] frame border-box">
                <div className="text-[18px] font-600 ">{num}</div>
                <div className="flex  justify-center pt-[30px]">
                    <img className="size-[100px] lg:size-[150px]" src={IMGLINK + img} alt=""/>
                </div>
                <div className="text-[24px] pt-[20px] lg:text-[32px] font-600">{text}</div>
                <div className="pt-[20px]">
                    <Button
                        onclick={() => handleClick(text)}
                        img={true}
                        color="green"
                        clasName="bg-green-brandGreen transition-all duration-500 h-[50px] text-[18px] hover:bg-transparent hover:text-green-brandGreen text-white"
                    >
                        Заказать сейчас
                    </Button>
                    {/* <Popup isOpen={isOpen} onClose={handleClose} /> */}

                </div>
            </div>
        </div>
    );
};

export default ServiceCard;
