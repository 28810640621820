import React, {useEffect, useState} from "react";
import Input from "../../Input";
import "./style.css";
import logo from "../../../../svg/preloader-gif.gif";
import Spinner from "../../../Spinner";

const Modals = ({isOpen, onClose, success}) => {
    const [message, setMessage] = useState('');
    const [response, setResponse] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const sendMessageToBackend = async () => {
        try {
            if (message.length < 3) {
                console.log('Message should be at least 3 characters long.')
                return;
            }

            setLoading(true)

            const response = await fetch(`https://api.xiam.uz/api/sending-info?info=${message}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    message,
                    title: isOpen
                })
            });

            const data = await response.json();
            if (data.success) {
                setResponse(data.info); // Assuming backend sends back a response message
                setMessage('')

                setShowSuccess(true)

                setTimeout(() => {
                    setShowSuccess(false)
                    onClose()
                }, 4000)
            }  // Assuming backend sends back a response message

        } catch (error) {
            console.error('Error sending message:', error);
            setResponse('Error sending message');
        }

        setLoading(false)
    };
    const press = (e) => {
        if (e.key === "Enter") {
            sendMessageToBackend()
            onClose();
        }
    }

    useEffect(() => {
        setShowSuccess(success)
    }, [success])

    return (
        <div className={
            isOpen
                ? "flex justify-center z-[99] bg-[#1A19195C] fixed h-full w-full top-0 right-0 items-center animation-modal show"
                : "flex justify-center z-[99] bg-[#1A19195C] fixed h-full w-full top-0 right-0 items-center animation-modal"
        }>
            <div className=" fixed z-50 rounded-[5px] max-w-[100%]">
                <div className={`relative overflow-x-auto  flex justify-center`}>
                    <div
                        className="bg-[white] p-[20px] md:w-[586px]  w-[355px] max-w-[100%] mx-[12px] z-0 relative rounded-[40px]">
                        <div className="p-[20px] frames border-box">
                            <div
                                onClick={() =>
                                    onClose()
                                }
                                className="stroke-[black] cursor-pointer p-[7px] flex justify-end"
                            >
                                <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1 1L17 17M1 17L17 1"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </div>

                            <div className="flex flex-col gap-[20px] font-[dudka] justify-center mb-[36px] items-center mt-[10px] md:mt-[20px]">
                                <div className="max-w-[90%] text-center modal-text">
                                    {showSuccess ? 'Заявка успешно отправлена' : 'Оставить заявку'}
                                </div>
                                <div
                                    className="leading-[25px] max-w-[319px] text-[18px] md:text-[20px] text-center text-[#767575]">
                                    {showSuccess
                                        ? 'Наши специалисты скоро свяжутся с вами'
                                        : 'Оставьте заявку и наши специалисты свяжутся с вами'
                                    }
                                </div>
                            </div>

                            {!showSuccess ? (
                                <div className="flex flex-col mb-[42px] px-[0px] md:px-[30px] md:mb-[52px] gap-[16px]">
                                    <div>
                                        <Input onKeyDown={press} value={message} onChange={handleMessageChange}
                                               placeholder={"Телеграм username"}/>
                                    </div>
                                    <div>
                                        <div
                                            className={`p-[6px] btn cursor-pointer rounded-[30px] borders-green`}
                                            onClick={() => sendMessageToBackend()}
                                        >
                                            <div
                                                className="send-btn px-[20px] h-[50px] py-[10px] rounded-[30px] cursor-pointer bg-green-brandGreen transition-all duration-500 hover:bg-transparent hover:text-green-brandGreen text-white flex justify-between items-center">
                                                <button>
                                                    Отправить
                                                </button>

                                                {/*<Spinner color={'#007C60'}/>*/}

                                                {loading ? (
                                                    <Spinner color={'#fff'} width={20} height={20}/>
                                                ) : (
                                                    <svg
                                                        width="31"
                                                        height="16"
                                                        viewBox="0 0 31 16"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M30.7071 8.70711C31.0976 8.31658 31.0976 7.68342 30.7071 7.29289L24.3431 0.928932C23.9526 0.538408 23.3195 0.538408 22.9289 0.928932C22.5384 1.31946 22.5384 1.95262 22.9289 2.34315L28.5858 8L22.9289 13.6569C22.5384 14.0474 22.5384 14.6805 22.9289 15.0711C23.3195 15.4616 23.9526 15.4616 24.3431 15.0711L30.7071 8.70711ZM0 9H30V7H0V9Z"/>
                                                    </svg>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
            <div
                onClick={onClose}
                className="bg-gray-modalBackground z-40 fixed top-0 left-0 w-full h-full flex justify-center items-center "
            ></div>
        </div>
    );
};

export default Modals;
