import React, {useEffect} from "react";
import Card from "../Generic/card";
import Swiper from "./Swiper";
import {Element} from 'react-scroll';
import Aos from 'aos'
import 'aos/dist/aos.css'
import {useValue} from "../../useValue";

const Review = () => {
    // State to store the fetched data
    const {anotherValue} = useValue();

    // const arr = anotherValue?.data?.[3].part_details?.[2].header

    useEffect(() => {
        Aos.init();
    }, []);

    return (
        <Card color={"bg-white md:py-[60px] md:px-[40px] py-[30px] px-[20px]"} id="@review">
            <Element name='review'></Element>
            <div className="flex flex-col items-center" id={'review'}>
                <div
                    className="text-center title md:max-w-[70%]"
                    data-aos="flip-up"
                    data-aos-duration={700}
                    data-aos-delay={0}
                >
                    {'Узнайте что говорят o нас наши клиенты'}
                </div>
            </div>
            <div>
                <Swiper/>
            </div>
        </Card>
    );
};

export default Review;
