import React, {useState} from "react";
import Input from "../Generic/Input";
import Card from "../Generic/card";
import "./style.css";
import {useValue} from "../../useValue";
import Spinner from "../Spinner";

const ContactUs = () => {
    const [message, setMessage] = useState("");
    const [response, setResponse] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const {setValue} = useValue();

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const sendMessageToBackend = async () => {
        setLoading(true)

        try {
            if (message.length < 3) {
                console.log("Message should be at least 3 characters long.");
                return;
            }

            const response = await fetch(
                `https://api.xiam.uz/api/sending-info?info=${message}&title=${'Свяжитесь с нами'}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    // body: JSON.stringify({ message })
                    body: JSON.stringify([]),
                }
            );

            const data = await response.json();
            if (data.success) {
                setResponse(data.info); // Assuming backend sends back a response message
                setMessage("");

                setShowSuccess(true)
                setValue('Свяжитесь с нами')

                setTimeout(() => {
                    setShowSuccess(false)
                    setValue(false)
                }, 4000)
            }
        } catch (error) {
            console.error("Error sending message:", error);
            setResponse("Error sending message");
        }

        setLoading(false)
    };

    const press = (e) => {
        if (e.key === "Enter") {
            sendMessageToBackend();
        }
    };

    return (
        <Card color="bg-[#191919] " id="@contact">
            <div className="w-[100%] md:p-[20px] p-[10px]" id={'contacts'}>
                <div
                    className="contact w-[100%] flex flex-col justify-center items-center md:py-[80px] py-[140px] px-[16px]">
                    <div
                        className="title text-white-important"
                        data-aos="flip-up"
                        data-aos-duration={700}
                        data-aos-delay={0}
                    >
                        Свяжитесь с нами
                    </div>
                    <div className="max-w-[448px] w-full flex flex-col gap-[20px] pt-[36px] md:w-[400px]">
                        <div
                            data-aos="fade-up"
                            data-aos-duration={700}
                            data-aos-delay={0}
                        >
                            <Input
                                onKeyDown={press}
                                value={message}
                                onChange={handleMessageChange}
                                placeholder={"Username в телеграм"}
                            />
                        </div>

                        <div onClick={sendMessageToBackend}>
                            <div
                                className={`p-[6px] btn cursor-pointer rounded-[30px] borders-green`}
                                data-aos="fade-up"
                                data-aos-duration={700}
                                data-aos-delay={0}
                            >
                                <div
                                    className="px-[20px] h-[50px] py-[10px] text-[18px] rounded-[30px] cursor-pointer bg-green-brandGreen transition-all duration-500  hover:bg-transparent hover:text-green-brandGreen text-white flex justify-between items-center"
                                >
                                    <div>Отправить</div>

                                    {loading ? (
                                        <Spinner color={'#fff'} width={20} height={20}/>
                                    ) : (
                                        <svg
                                            width="31"
                                            height="16"
                                            viewBox="0 0 31 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M30.7071 8.70711C31.0976 8.31658 31.0976 7.68342 30.7071 7.29289L24.3431 0.928932C23.9526 0.538408 23.3195 0.538408 22.9289 0.928932C22.5384 1.31946 22.5384 1.95262 22.9289 2.34315L28.5858 8L22.9289 13.6569C22.5384 14.0474 22.5384 14.6805 22.9289 15.0711C23.3195 15.4616 23.9526 15.4616 24.3431 15.0711L30.7071 8.70711ZM0 9H30V7H0V9Z"/>
                                        </svg>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default ContactUs;
