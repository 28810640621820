import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./style.css";

import { Pagination, Navigation } from "swiper/modules";
import SwiperCard from "./SwiperCard";
import { useValue } from "../../../useValue";

const Swipers = () => {
  const { anotherValue } = useValue();
  const [arr, setArr] = useState([]);

  const swiperRef = useRef();
  const swipersRef = useRef();

  useEffect(() => {
    setArr(anotherValue.data?.[3]?.part_details) 
  }, [anotherValue]);
  return (
    <>
      <div
          className=" md:flex md:flex-col w-full mt-[32px] mx-auto  md:justify-center"
          data-aos="fade-up"
          data-aos-duration={700}
          data-aos-delay={0}
      >
        {/* big size =================================================================>*/}

        <div className="md:flex hidden justify-center mb-[48px] gap-[16px]">
          <div className="flex h-auto">
            <div
              // onClick={() => swiperRef.current.slidePrev()}
              className="wraps slide-prev"
            >
              <div className={` top rounded-full  p-[15px]`}>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M15.5 6.98756C15.5 6.43528 15.0523 5.98756 14.5 5.98756L4.22519 5.98756L7.94353 2.11906C8.31263 1.73506 8.31263 1.12812 7.94353 0.744116V0.744116C7.55318 0.338003 6.90342 0.338003 6.51307 0.744116L1.16608 6.30703C0.794025 6.6941 0.794026 7.3059 1.16608 7.69298L6.51307 13.2559C6.90342 13.662 7.55318 13.662 7.94353 13.2559V13.2559C8.31263 12.8719 8.31263 12.2649 7.94353 11.8809L4.22519 8.01244L14.5 8.01244C15.0523 8.01244 15.5 7.56472 15.5 7.01244L15.5 6.98756Z" />
                </svg>
              </div>
            </div>
          </div>
          <div className="flex h-auto">
            <div
              // onClick={() => swiperRef.current.slideNext()}
              className="wraps slide-next"
            >
              <div className={` top rounded-full rotate-[180deg] p-[15px]`}>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M15.5 6.98756C15.5 6.43528 15.0523 5.98756 14.5 5.98756L4.22519 5.98756L7.94353 2.11906C8.31263 1.73506 8.31263 1.12812 7.94353 0.744116V0.744116C7.55318 0.338003 6.90342 0.338003 6.51307 0.744116L1.16608 6.30703C0.794025 6.6941 0.794026 7.3059 1.16608 7.69298L6.51307 13.2559C6.90342 13.662 7.55318 13.662 7.94353 13.2559V13.2559C8.31263 12.8719 8.31263 12.2649 7.94353 11.8809L4.22519 8.01244L14.5 8.01244C15.0523 8.01244 15.5 7.56472 15.5 7.01244L15.5 6.98756Z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
        {/* small size =================================================================>*/}

        <div className="flex justify-center md:hidden mb-[48px] gap-[16px]">
          <div className="flex h-auto">
            <div
              onClick={() => swipersRef.current.slidePrev()}
              className="wraps  "
            >
              <div className={` top rounded-full  p-[15px]`}>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M15.5 6.98756C15.5 6.43528 15.0523 5.98756 14.5 5.98756L4.22519 5.98756L7.94353 2.11906C8.31263 1.73506 8.31263 1.12812 7.94353 0.744116V0.744116C7.55318 0.338003 6.90342 0.338003 6.51307 0.744116L1.16608 6.30703C0.794025 6.6941 0.794026 7.3059 1.16608 7.69298L6.51307 13.2559C6.90342 13.662 7.55318 13.662 7.94353 13.2559V13.2559C8.31263 12.8719 8.31263 12.2649 7.94353 11.8809L4.22519 8.01244L14.5 8.01244C15.0523 8.01244 15.5 7.56472 15.5 7.01244L15.5 6.98756Z" />
                </svg>
              </div>
            </div>
          </div>
          <div className="flex h-auto">
            <div
              onClick={() => swipersRef.current.slideNext()}
              className="wraps  "
            >
              <div className={` top rounded-full rotate-[180deg] p-[15px]`}>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M15.5 6.98756C15.5 6.43528 15.0523 5.98756 14.5 5.98756L4.22519 5.98756L7.94353 2.11906C8.31263 1.73506 8.31263 1.12812 7.94353 0.744116V0.744116C7.55318 0.338003 6.90342 0.338003 6.51307 0.744116L1.16608 6.30703C0.794025 6.6941 0.794026 7.3059 1.16608 7.69298L6.51307 13.2559C6.90342 13.662 7.55318 13.662 7.94353 13.2559V13.2559C8.31263 12.8719 8.31263 12.2649 7.94353 11.8809L4.22519 8.01244L14.5 8.01244C15.0523 8.01244 15.5 7.56472 15.5 7.01244L15.5 6.98756Z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
        {/* big size  =================================================================>*/}
        <div className="w-full hidden  md:flex md:justify-center md:items-center">
          <Swiper
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
            speed={800}
            slidesPerView={3}
            spaceBetween={20}
            navigation={{
              nextEl: '.slide-next',
              prevEl: '.slide-prev'
            }}
            loop={true}
            modules={[Pagination, Navigation]}
            className="mySwiper"
          >
            <div className="stretcher">
              {arr?.map((item) => (
                <SwiperSlide>
                  <SwiperCard hero={item.icon} article={item.content} />
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
        </div>
        {/* small size =================================================================>*/}
        <div className="my-auto flex justify-center">
          <div className="w-full flex md:hidden my-auto justify-center items-center">
            <Swiper
              onSwiper={(swiper) => {
                swipersRef.current = swiper;
              }}
              speed={800}
              slidesPerView={1}
              spaceBetween={20}
              navigation={{
                nextEl: '.slide-next',
                prevEl: '.slide-prev'
              }}
              loop={true}
              modules={[Pagination, Navigation]}
              className="mySwiper"
            >
              <div className="stretcher">
                {arr?.filter((value)=>value.content!==null)?.map((item) => (
                  <SwiperSlide>
                    <SwiperCard hero={item.icon} article={item.content} />
                  </SwiperSlide>
                ))}
              </div>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
};

export default Swipers;
