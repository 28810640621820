import React from "react";

const Input = ({ placeholder, onKeyDown, danger, onChange, ...props }) => {
  return (
    <div>
      <input
        {...props}
        onKeyDown={onKeyDown}
        onChange={onChange}
        className={`${danger} outline-none w-full cursor-text h-[58px] bg-[#F5F5F5] p-[10px] px-[20px] rounded-[50px]`}
        type="text"
        placeholder={placeholder}
      />
    </div>
  );
};

export default Input;
