import React, {useEffect} from "react";
import Card from "../Generic/card";
import Quests from "../Generic/Questions";
import {Element} from "react-scroll";
import Aos from "aos";
import "aos/dist/aos.css";
import {useValue} from "../../useValue";

const Faq = () => {
    const {anotherValue} = useValue();

    useEffect(() => {
        Aos.init();
    }, []);

    const arr = anotherValue?.data?.[2].part_details;

    return (
        <Card color={"bg-white md:py-[60px] md:px-[40px] py-[30px] px-[20px]"} id="@faq">
            <Element name="faq"></Element>
            <div className="flex justify-center" id={'faq'}>
                <div
                    className="title flex justify-center md:w-[50%]"
                    data-aos="flip-up"
                    data-aos-duration={700}
                    data-aos-delay={0}
                >
                    FAQ
                </div>
            </div>

            <div className="flex flex-col mt-[36px] gap-[15px]">
                {arr?.map((value, i) => (
                    <Quests
                        delay={i * 100}
                        question={value.header}
                        answer={value.content}
                    />
                ))}
            </div>
        </Card>
    );
};

export default Faq;
